export const about = [
    { id: 1, text: `Мебель по индивидуальным проектам в Белгороде` },
    { id: 2, text: `Наша компания имеет большой опыт в производстве уникальной мебели
    для вашей кухни.` },
    { id: 3, text: `Специалисты нашей компании используют современные подходы и новейшие
    решения в проектировании и реализации пространства внутри Вашего
    дома.` },
    { id: 4, text: `Каждая наша кухня разрабатывается по индивидуальным предпочтениям
    заказчика, учитывая их образ и привычки, воплощая в мебели
    максимальный комфорт и удобство для жизни.` },
    { id: 5, text: `Если вы разделяете наши ценности, то мы готовы реализовать все
    предпочтения в дизайне Вашей кухни.` },
]