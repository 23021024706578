export const data = [{
        id: 1,
        path: "/",
        class: "nav_link",
        name: 'ГЛАВНАЯ',
    },
    {
        id: 2,
        path: "/portfolio",
        class: "nav_link",
        name: 'ПОРТФОЛИО',
    },
    {
        id: 3,
        path: "/stages",
        class: "nav_link",
        name: 'ЭТАПЫ РАБОТ',
    },
    {
        id: 4,
        path: "/blog",
        class: "nav_link",
        name: 'БЛОГ',
    },
    {
        id: 5,
        path: "/contacts",
        class: "nav_link",
        name: 'КОНТАКТЫ',
    },
]