export const textSwiper = [{
        id: 1,
        text: `Спасибо вам за кухню и ваше отношение, очень приятно было работать с вами,
    спасибо за ваше дело и сотрудников) кухня бомба, всё как хотели, так и
    получилось))) все друзья и родственники в восторге!`,
    },
    {
        id: 2,
        text: `Спасибо Вам огромное, дорогие помощники наши! Я до сих пор захожу на кухню и
    не могу нарадоваться! 2 месяца мы выбирали кухню, и случайно в инстаграмме
    нашли Вас. Анастасия профессионал своего дела, сразу поняла, что мы хотим,
    предложила интересные варианты.`,
    },
    {
        id: 3,
        text: `Спасибо большое за реализацию наших идей, за ваш профессионализм, терпение,
    ответственность и отзывчивость!`,
    },
    {
        id: 4,
        text: `Месяц как пользуемся кухней. Очень довольны покупкой. Спасибо Вам за помощь
    при составлении проекта кухни. Отличное качество фасадов, удобна в
    использовании, грамотное расположение рабочей зоны.`,
    },
    {
        id: 5,
        text: `Спасибо за прекрасную работу, за потрясающую кухню и терпение. Очень жаль,
    что фото не может передать всю красоту цвета, а площадь помещения не
    позволяет вместить в одну фотографию всю кухню.`,
    },
    {
        id: 6,
        text: `Я очень благодарна Вам за вашу работу. Не перестаю наслаждаться своей
    кухней. Спасибо!!! Удачи Вам, радости, счастья и любви!`,
    },
]