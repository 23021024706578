import React from 'react'
import { InDeveloping } from '../InDeveloping/InDeveloping'
import './Blog.scss'

export const Blog = () => {
    return (
        <div>
            <InDeveloping />
        </div>
    )
}
