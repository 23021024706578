import React from 'react'
import { FormSendMessage } from '../Home/Forms/FormSendMessage/FormSendMessage'
import './Contacts.scss'

export const Contacts = () => {
    return (
        <div>
            <FormSendMessage />
        </div>
    )
}
